import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = ({ image }) => {
  const img = getImage("../../images/CTAOpengroupe.png");

  return (
    <GatsbyImage
      className="rounded-lg shadow-lg my-6 w-full"
      image={img}
      alt="Mon image"
    />
  );
};

export default Image;