import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const cta = ({ href }) => (
  <a href={href} alt="Expert fissures Cabinet E2P">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA Pascal PLOIX.png"
      alt="Cabinet E2P"
    />
  </a>
)

export default cta