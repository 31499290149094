import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const cta = ({ href }) => (
  <a href={href} alt="Expert fissures Lunoa Expert Conseil">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA BENAMAR.png"
      alt="Lunoa Expert Conseil"
    />
  </a>
)

export default cta